import React from "react"
import Layout from "../components/layout"
import PrivacySection from "../components/privacy_section"
export default function PrivacyCookies() {
  return (
    <Layout showButtons={false}>
      <section>
        <div className="max-w-screen-sm px-8 mx-auto pt-36 md:max-w-screen-md lg:max-w-screen-md">
          <h1 className="pb-2 text-xl text-center">
            POLITYKA COOKIES SERWISU ONLINE
          </h1>
          <h2 className="text-center">
            <strong>lappka.pl</strong>
          </h2>
          <p className="text-center">Obowiązuje od: 14.02.2021</p>

          <div className="py-12 space-y-12">
            <PrivacySection number="1" title="Stosowane definicje">
              <ol className="list-decimal">
                <li>
                  <ol className="pl-10 list-alpha">
                    <li>
                      <strong>Cookies</strong> - smałe pliki, które są pobierane
                      i przechowywane na komputerze lub innym urządzeniu
                      Użytkownika, powiązanym z informacjami o sposobie
                      użytkowania strony.
                    </li>
                    <li>
                      <strong>Użytkownik</strong> - osoba, która dobrowolnie
                      korzysta z usług i treści dostępnych w Serwisie tj.
                      przegląda strony Serwisu, zapisuje się na bezpłatny
                      newsletter lub kontaktuje się z właścicielem Serwisu
                      poprzez formularz kontaktowy,
                    </li>
                    <li>
                      <strong>Administrator danych osobowych</strong> - podmiot
                      decydujący o celach i środkach przetwarzania danych.
                      Administratorem danych osobowych Użytkowników serwisu jest
                    </li>
                    <li>
                      <strong>Dane osobowe</strong> - wszelkie informacje, które
                      bez nadmiernego czasu i kosztu mogą doprowadzić do
                      identyfikacji osoby fizycznej, w tym jej dane
                      identyfikacyjne, adresowe i kontaktowe.
                    </li>
                  </ol>
                </li>
              </ol>
            </PrivacySection>
            <PrivacySection number="2" title="Postanowienia ogólne">
              <ol className="list-decimal">
                <li>
                  <ol className="pl-10 list-alpha">
                    <li>
                      Serwis używa plików cookies, aby dostarczać usługi i
                      funkcje dostosowane do preferencji i potrzeb Użytkowników,
                      w szczególności pliki cookies pozwalają rozpoznać
                      urządzenie Użytkownika i odpowiednio wyświetlić stronę
                      internetową, dostosowaną do jego indywidualnych potrzeb.
                    </li>
                    <li>
                      Pliki cookies mogą mieć charakter tymczasowy, tj. są
                      usuwane z chwilą zamknięcia przeglądarki lub trwały.
                    </li>
                    <li>
                      Stałe pliki cookies są przechowywane także po zakończeniu
                      korzystania ze stron Serwisu i służą do przechowywania
                      informacji takich jak hasło czy login, co przyspiesza i
                      ułatwia korzystanie ze stron, a także umożliwia
                      zapamiętanie wybranych przez Użytkownika ustawień.
                    </li>
                  </ol>
                </li>
              </ol>
            </PrivacySection>

            <PrivacySection number="3" title="Cele cookies">
              <ol className="list-decimal">
                <li>
                  Serwis wykorzystuje pliki cookies w następujących celach:
                  <ol className="pl-10 list-alpha">
                    <li>
                      dostosowania zawartości stron Serwisu do indywidualnych
                      preferencji Użytkownika oraz optymalizacji korzystania ze
                      stron internetowych (wyświetlanie dostosowane do potrzeb),
                    </li>
                    <li>
                      dokonania analiz i statystyk, które dostarczą informacji,
                      w jaki sposób Użytkownik Serwisu korzysta ze stron, co
                      umożliwia ulepszanie ich struktury i zawartości,
                    </li>
                    <li>
                      utrzymanie sesji Użytkownika (po zalogowaniu), dzięki
                      której Użytkownik nie musi na każdej podstronie ponownie
                      wpisywać loginu i hasła.
                    </li>
                  </ol>
                </li>
                <li>
                  Dane zawarte w cookies będą archiwizowane i wykorzystywane w
                  celach analizy statystycznej i oceny globalnego ruchu
                  użytkowników Serwisu.
                </li>
                <li>
                  W celu analizy ruchu na stronie Serwisu, źródła ruchu, jak i
                  optymalizacji działań marketingowych zainstalowaliśmy na
                  stronie:
                  <ol className="pl-10 list-alpha">
                    <li>
                      Google Analytics, co pozwala na analizę ruchu Użytkownika
                      na stronie Serwisu i jego podstronach,
                    </li>
                  </ol>
                </li>
                <li>
                  Dane zawarte w cookies nie są łączone z przekazanymi przez
                  Użytkownika danymi osobowymi.
                </li>
              </ol>
            </PrivacySection>

            <PrivacySection
              number="4"
              title="Akceptacja lub zmiana ustawień cookies"
            >
              <ol className="list-decimal">
                <li>
                  <ol className="pl-10 list-alpha">
                    <li>
                      Klikając ”Zgadzam się/Akceptuję/Rozumiem” w okienku z
                      informacją o cookies Użytkownik zgadza się na użycie
                      cookies w wyżej wymienionych celach.
                    </li>
                    <li>
                      Użytkownik może w każdej chwili skasować i/lub wyłączyć
                      cookies w przeglądarce internetowej, z której korzystasz
                      podczas przeglądania stron.
                    </li>
                    <li>
                      Niektóre pliki cookies - tzw. cookies funkcjonalne mogą
                      być niezbędne do prawidłowego działania stron Serwisu.
                    </li>
                    <li>
                      Użytkownik może dokonać w każdym czasie zmiany ustawień
                      dotyczących plików cookies w ustawieniach przeglądarki
                      internetowej (opcja „pomoc”), z której korzysta do
                      przeglądania stron Serwisu.
                    </li>
                    <li>
                      Ustawienia plików cookies mogą zostać zmienione w taki
                      sposób, aby blokować automatyczną obsługę plików cookies w
                      ustawieniach przeglądarki internetowej bądź informować o
                      ich każdorazowym zamieszczeniu w urządzeniu Użytkownika.
                    </li>
                    <li>
                      Skasowanie lub wyłączenie plików cookies może spowodować
                      trudności lub ograniczoną funkcjonalność niektórych części
                      strony (dotyczy cookies funkcjonalnych).
                    </li>
                  </ol>
                </li>
              </ol>
            </PrivacySection>

            <PrivacySection number="5" title="Postanowienia końcowe">
              <ol className="list-decimal">
                <li>
                  Serwis zastrzega możliwość zmiany niniejszej Polityki cookies
                  w sytuacji, gdy będą tego wymagały zastosowane rozwiązania
                  techniczne lub zmiany przepisów prawa w zakresie prywatności
                  Użytkowników.
                </li>
                <li>
                  W przypadku zmiany obowiązującej polityki cookies, wprowadzone
                  zostaną odpowiednie modyfikacje do powyższego zapisu, które
                  będą obowiązywać od dnia ich publikacji na stronach Serwisu.
                </li>
              </ol>
            </PrivacySection>
          </div>
        </div>
      </section>
    </Layout>
  )
}
